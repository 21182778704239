import { alertController } from '@ionic/vue';
//import { Plugins } from "@capacitor/core";
//const { Geolocation } = Plugins;

async function obtenerUbicacion() {
	return new Promise((resolve) => {
//		const resolve = (x: any) => (console.log(x),resolve(x));
		if (!navigator || !navigator.geolocation) return resolve(false);

		try {
			navigator.geolocation.getCurrentPosition(resolve, function() {
				navigator.geolocation.getCurrentPosition(resolve, function() { resolve(false); }, {
					enableHighAccuracy: false,
					timeout: 15000,
					maximumAge: 60000,
				})
			}, {
				enableHighAccuracy: true,
				timeout: 15000,
				maximumAge: 60000,
			})
		} catch(e) {
			return resolve(false);
		}

	});
}
/*
async function obtenerUbicacionOld() {
	return Geolocation.getCurrentPosition({
		enableHighAccuracy: true,
		timeout: 15000,
		maximumAge: 60000,
	})
	.catch(function () {
		return Geolocation.getCurrentPosition({
			enableHighAccuracy: false,
			timeout: 15000,
			maximumAge: 60000,
		}).catch(function () {
			return false;
		});
	});
}
*/
async function geoAccion(
	mensajeConfirmar: string,
	mensajeNoUbicado: string
): Promise<any> {
	// confirmación

	const confirmExternoPromise = (): Promise<boolean> => {
		return new Promise((resolve) => {
			if (mensajeConfirmar == '') return resolve(true);
			alertController
				.create({
					message: mensajeConfirmar,
					buttons: [
						{
							text: "No",
							role: "cancel",
							handler: () => resolve(false),
						},
						{
							text: "Sí",
							handler: () => resolve(true),
						},
					],
				})
				.then((alert) => alert.present());
		});
	};

	const c1 = await confirmExternoPromise();

	if (!c1) return { confirmed: false };

	const confirmInternoPromise = (): Promise<boolean> => {
		return new Promise((resolve) => {
			if (mensajeNoUbicado == '') return resolve(true);
			alertController
				.create({
					message: mensajeNoUbicado,
					buttons: [
						{
							text: "Enviar sin ubicación",
							handler: () => resolve(true),
						},
						{
							text: "Lo reintentaré más tarde",
							handler: () => resolve(false),
						},
					],
				})
				.then((alert) => alert.present());
		});
	};

	const place = await obtenerUbicacion();

	let lon,
		lat,
		confirmed = false;

	if (!place 
			|| typeof place !== 'object' 
			|| typeof (place as any).coords === 'undefined' 
			|| typeof (place as any).coords.longitude === 'undefined'
			|| typeof (place as any).coords.latitude  === 'undefined') {
		lon = "";
		lat = "";

		confirmed = await confirmInternoPromise();
	} else {
		confirmed = true;
		lon = (place as any).coords.longitude;
		lat = (place as any).coords.latitude;
	}

	return { confirmed, lon, lat };
}

export { geoAccion, obtenerUbicacion };
export default geoAccion;