<template>
  <ion-page><cyan-page-wrapper nav1="CONTROL ELECTORAL" 
    :burst="false"
    backRoute="/home"
    :iccNoPad="true"
    :iccFlex="true"
    :conDirectorio="$store.getters.userHasPermission('jrsl')"
    :title="esAzul ? 'Exit Poll' : 'Cyan'"
    >

    <boton-directorio-streamlined class="mobileShow" v-if="$store.getters.userHasPermission('jrsl')"></boton-directorio-streamlined>

    <user-data class="cabeceraUsuario mobileShowFlex" :bottom="$store.getters.userHasPermission('jrsl')" />

    <streamlined-home></streamlined-home>

  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import { IonPage } from "@ionic/vue";
import UserData from "@/components/UserData.vue";
import StreamlinedHome from "@/components/StreamlinedHome.vue"
import BotonDirectorioStreamlined from '@/components/BotonDirectorioStreamlined.vue';
export default defineComponent({
  name: "Home",
  components: {
    IonPage,
    BotonDirectorioStreamlined,
    CyanPageWrapper,
    UserData,
    StreamlinedHome,
  },
  setup() {
    return {
      
    }
  },
  computed: {
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p('busl') && !p('jrsl') && !p('pcsl') && !p('dgsl');
    }
  },
  methods: {},
});
</script>

<style scoped>
ion-button div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ion-button {
  height: 180px;
  width: 180px;
  margin: 20px;
}
ion-button ion-icon {
  padding-bottom: 20px;
  font-size: 80px;
}

@media (max-height: 400px) {
  .cabeceraUsuario  {
    display: none;
  }
}

.cabeceraUsuario { padding: 4vh 2vh 2vh; }

</style>