
import CyanPageWrapper, {
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import { IonPage } from "@ionic/vue";
import UserData from "@/components/UserData.vue";
import StreamlinedHome from "@/components/StreamlinedHome.vue"
import BotonDirectorioStreamlined from '@/components/BotonDirectorioStreamlined.vue';
export default defineComponent({
  name: "Home",
  components: {
    IonPage,
    BotonDirectorioStreamlined,
    CyanPageWrapper,
    UserData,
    StreamlinedHome,
  },
  setup() {
    return {
      
    }
  },
  computed: {
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p('busl') && !p('jrsl') && !p('pcsl') && !p('dgsl');
    }
  },
  methods: {},
});
